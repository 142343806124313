/*
==================== РУБРИКАТОР ====================
*/
$cheronUp: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E");
$cheronDown: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");

$rubricatorBackground: $white;
$rubricatorTextColor:             $mblack;               // Цвет текста #000
$rubricatorDivider:               $gray-400;

$rubricatorBackgroundHover:       $white;              // Цвет фона при наведении #eaeaea
$rubricatorTextColorHover:        $mblack;               // Цвет текста при наведении #000

$rubricatorBorderRadius:          0;                  // Скругление углов
$rubricatorBorderColor:           $gray-400;

$rubricatorInputBackground:       $white;                 // Цвет фона инпута
$rubricatorInputBorderColor:      $gray-500;
$rubricatorInputTextColor:        $mblack;              // Цвет текста инпута
$rubricatorInputTextShadow:       1px 1px 1px rgba(0, 0, 0, .2);  // Цвет тени 1px 1px 1px rgba(0, 0, 0, .2)

$rubricatorInputDotDefaultColor:  rgba(0,0,0,0.2); // Цвет точки по-умолчанию
$rubricatorInputDotDefaultShadow: 1px 1px 1px rgba(0, 0, 0, 0); // Цвет тени 1px 1px 1px rgba(0, 0, 0, .2)

$baloonBackground:                $rubricatorBackground;
$baloonTextColor:                 $mblack;

$baloonBorderRadius:              4px;

$baloonArrowColor:                $gray-400;
$baloonArrowBackColor:            $gray-500;
$baloonBorderColor:               $gray-400;

@mixin ex-opacity($opacity) {
  -moz-opacity: $opacity;
  -khtml-opacity: $opacity;
  -webkit-opacity: $opacity;
  opacity: $opacity;
}

@mixin ex-label-color($color) {
    background-color: $color;
    border-color: darken($color, 25%);
    color: contrast($color);
  }

.ex-chevron.ex-expand {
  font-size: 26px;
  cursor: pointer;
}
.ex-state-default {
  .ex-chevron.ex-expand {
    &::after{
      content: $cheronUp;
    }
    //background-image: escape-svg($cheronUp);
  }
}

.ex-state-collapsed {
  .ex-chevron.ex-expand {
    &::after{
      content: $cheronDown;
    }
    //background-image: escape-svg($cheronUp);
  }
}

.ex-catalog-filter {
  background: $rubricatorBackground;
  border-radius: $rubricatorBorderRadius;
  color: $rubricatorTextColor;
  margin-bottom: 20px;
  border: none;

  & > ul {
    margin: 0;
    padding: 0;
  }

  li {
    vertical-align: top;
    list-style: none;
  }

  .ex-catalog-filter-item:first-child, .ex-catalog-filter-item-block:first-child {
    background-image: none;
    border-radius: $rubricatorBorderRadius $rubricatorBorderRadius 0 0;
  }

  .ex-catalog-filter-item:last-child, .ex-catalog-filter-item-block:last-child {
    border-radius: 0 0 $rubricatorBorderRadius $rubricatorBorderRadius;
    margin-top: 15px;
  }

}


.ex-catalog-filter .ex-catalog-filter-item,
.ex-rubricator li[data-has-children="true"][data-branch] {
  padding: 2px 15px;
}


.ex-catalog-filter .ex-catalog-filter-item:hover,
.ex-rubricator li[data-has-children][data-branch]:hover {
  background: $rubricatorBackgroundHover;
  border-radius: $rubricatorBorderRadius;
  color: $rubricatorTextColorHover;
}

.ex-catalog-filter-item,
.ex-rubricator li[data-has-children][data-branch] {
  -moz-transition: background-color .25s ease,color .35s ease,border .25s ease;
  -webkit-transition: background-color .25s ease,color .35s ease,border .25s ease;
  transition: background-color .25s ease,color .35s ease,border .25s ease;
}

.ex-price-range {
  height: 0;
  overflow: hidden;
  position: relative;
}

.ex-price-range.ex-state-enabled {
  height: inherit;
  overflow: inherit;
  position: inherit;
}

.ex-rubricator-content {
  font-size: 14px;
  line-height: 26px;
}

.ex-details {
  padding: 0;
  margin: 0;
}

.ex-state-collapsed .ex-details {
  display: none;
}

.ex-details li {
  list-style-type: none;
  position: relative;
}

li[data-branch] .ex-title {
  //border-bottom: 1px dotted $rubricatorTextColor;
  cursor: pointer;
  font-weight: bold;
}

li[data-method="determinant"] > .ex-title {
  border-bottom: 1px dotted $rubricatorTextColor;
  cursor: pointer;
  font-weight: bold;
  text-transform: inherit;
}

.ex-catalog-filter .ex-catalog-filter-item:hover li[data-branch] .ex-title,
.ex-catalog-filter .ex-catalog-filter-item:hover li[data-method="determinant"] > .ex-title,
.ex-rubricator li[data-has-children][data-branch]:hover li[data-branch] .ex-title,
.ex-rubricator li[data-has-children][data-branch]:hover li[data-method="determinant"] > .ex-title {
  border-bottom: 1px dotted $rubricatorTextColorHover;
}

li[data-method="determinant"] > .ex-ico-details {
  position: absolute;
  top: 3px;
}

.ex-price-block > div {
  text-transform: uppercase;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}

li[data-slug] > .ex-details {
   padding-left: 20px;
}

li[data-method="determinant"] > .ex-details {
  padding-left: 0px;
  margin-bottom: 20px;
  margin-top: 5px;
  margin-left: 12px;
}

li[data-trunk] > .ex-details {
  padding-left: 0px;
  margin-left: 0px;
}

li[data-has-children] > .ex-details {
  margin-bottom: 5px;
}

li[data-has-children][data-trunk] > .ex-details {
    margin-bottom: 0;
}

li[data-branch] > .ex-details { /* visual rubricator root level */
  margin-top: 5px;
  margin-left: 12px;
}

.ex-details .ex-label {
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  display: inline;
  line-height: 22px;
}

.ex-details .ex-label:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -22px;
  top: 0;
  background: $rubricatorInputBackground;
  border: 1px solid $mblack;//$rubricatorInputBorderColor;
}

li[data-is-checked="true"] > .ex-label {
  font-weight: bold;
}

.ex-rubricator .ex-label:before {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
}

li[data-method="hierarchy"] > .ex-details > li > .ex-label:before { /* radio */
    border-radius: 8px;
}

li[data-method="facet"] > .ex-details > li > .ex-label:before { /* checkbox */
	border-radius: 4px;
}

li[data-method="hierarchy"] > .ex-details > li[data-is-checked="true"] > .ex-label:before {
    content: "\f111";
    /*color: $rubricatorInputTextColor;*/
    font-size: 6px;
    text-align: center;
    line-height: 13px;
    text-shadow: $rubricatorInputTextShadow;
}

li[data-method="facet"] > .ex-details > li[data-is-checked="true"] > .ex-label:before {
	content: "\f00c";
	text-shadow: $rubricatorInputTextShadow;
	font-size: 11px;
	/*color: $rubricatorInputTextColor;*/
	text-align: center;
    line-height: 13px;
}

li[data-method="facet"][data-is-children-checked="false"] > .ex-details > li > .ex-label:before {
    //content: "\f0c8";
    color: $rubricatorInputDotDefaultColor;
    text-shadow: $rubricatorInputDotDefaultShadow;
    font-size: 6px;
    text-align: center;
    line-height: 13px;
}

/* ========== Selection Assistant ========= */
li {
  & > .ex-label {
    @include ex-opacity(1);
    transition: opacity 0.2s linear;
  }

  &[data-is-real="false"] > .ex-label {
    @include ex-opacity(.55);
  }
  /*&[data-is-potential="false"] > .ex-label {
    @include ex-opacity(.15);
    text-decoration: line-through;
    display: none;
  }*/
  &[data-is-potential="false"] {
    display: none;
  }
}



/* ========== Всплывающая подсказка ========== */
.ex-rubricator {

  .ex-description-wrapper {
    position: relative;
    border-bottom: 0!important;

    .ex-baloon {
      display: none;
      position: absolute;
      top: -9px;
      left: 30px;
      z-index: 1010;
      width: 250px;
      background-color: $baloonBackground;
      border: 1px solid $baloonBorderColor;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      background-clip: padding-box;
      line-height: 15px;
      padding: 10px;
      color: $baloonTextColor;
      border-radius: $baloonBorderRadius;
      font-size: 11px;

      p {
        margin: 0;
        padding-right: 15px;
        text-transform: none;
        font-weight: normal;
        font-size: 11px;
      }
    }

  }

  .ex-state-description > .ex-description-wrapper .ex-baloon {
    display: block;
  }

  .ex-baloon {
    button.ex-close {
      margin-right: -10px;
      margin-top: -10px;

      &:hover {
        @include ex-opacity(1);
        text-decoration: none;
        border-bottom: 0;
      }
    }

    .ex-arrow {
      left: -7px;
      top: 10px;
      border-right-color: $baloonArrowColor;
      border-width: 7px 7px 7px 0px;

      &, &:after {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
      }

      &:after {
        z-index: -1;
        content: "";
        border-right-color: $baloonArrowBackColor;
        border-width: 9px 9px 9px 0;
        bottom: -9px;
        left: -2px;
      }
    }

  }
}


/* ========== Свернуто / развернуто ========== */

.ex-icon-details {
  position: absolute;
  top: 3px;
  left: -7px;
}

.ex-rubricator li[data-has-children][data-branch] > .ex-icon-details {
  position: relative;
  top: 0;
  left: 0;
}

.ex-state-collapsed .ex-details {
  display: none;
}

/* ========== Цвета меток ========== */

.ex-rubricator {

  li[data-tags~="beige"] > .ex-label:before {
    @include ex-label-color(#F5F5DC);
  }
  li[data-tags~="orange"] > .ex-label:before {
    @include ex-label-color($orange);
  }
  li[data-tags~="yellow"] > .ex-label:before {
    @include ex-label-color($yellow);
  }
  li[data-tags~="green"] > .ex-label:before {
    @include ex-label-color($green);
  }
  li[data-tags~="skyblue"] > .ex-label:before {
    @include ex-label-color(lighten($blue, 20%));
  }
  li[data-tags~="blue"] > .ex-label:before {
    @include ex-label-color(darken($blue-900, 10%));
  }
  li[data-tags~="gray"] > .ex-label:before {
    @include ex-label-color($gray-200);
  }
  li[data-tags~="purple"] > .ex-label:before {
    @include ex-label-color($purple);
  }
  li[data-tags~="pink"] > .ex-label:before {
    @include ex-label-color(lighten($pink, 20%));
  }
  li[data-tags~="lightgreen"] > .ex-label:before {
    @include ex-label-color(#7CFC00);
  }
  li[data-tags~="fuchsia"] > .ex-label:before {
    @include ex-label-color(#FF1493);
  }
  li[data-tags~="brown"] > .ex-label:before {
    @include ex-label-color(#8B4513);
  }
  li[data-tags~="white"] > .ex-label:before {
    @include ex-label-color($white);
  }
  li[data-tags~="black"] > .ex-label:before {
    @include ex-label-color($black);
  }
  li[data-tags~="red"] > .ex-label:before {
    @include ex-label-color($red);
  }
  li[data-tags~="turquoise"] > .ex-label:before {
    @include ex-label-color(#40E0D0);
  }
  li[data-tags~="darkorchid"] > .ex-label:before {
    @include ex-label-color(#9966CC);
  }
  li[data-tags~="greenyellow"] > .ex-label:before {
    @include ex-label-color(#ADFF2F);
  }

  li[data-tags~="multicolor"] > .ex-label:before {

    border-color: darken($gray-200, 10%);
    color: contrast($danger);
    @include gradient-y-three-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $end-color: $red);
    //.ex-gradient($color: $ex-red; $start: $purple; $stop: $red; $stop1: $blue; $stop2: $green; $stop3: $yellow; $stop4: $orange);

  }

}


/*
==================== ЗАГРУЗЧИК ====================
*/


.ex-rubricator {
  @include ex-opacity(1);
  transition: opacity 0.6s linear;

  &.ex-state-loading {
    @include ex-opacity(.3);
  }
}

.jslider-single .jslider-pointer-to,
.jslider-single .jslider-value-to,
.jslider-single .jslider-bg .v,
.jslider-limitless .jslider-label,
.jslider-labelless .jslider-value { display: none; }

.jslider {
  display: block;
  width: 100%;
	height: 30px;
	position: relative;
	top: 18px;
	margin-top: 10px;

  .jslider-bg {
    position: relative;
    i {
      height: 3px;
      position: absolute;
      font-size: 0;
      top: 0;
      background-color: $gray-300;
    }
    .l { width: 10%; background-position: 0 0; left: 0; }
    .f { width: 80%; left: 10%;}
    .r { width: 10%; left: 90%; background-position: right 0; }
    .v { position: absolute; width: 60%; left: 20%; top: 0; height: 4px; background-color: $danger }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    vertical-align: top;
    tr {
      width: 100%;
      vertical-align: top;
    }
  }
  td, th {
    padding: 0;
    vertical-align: top;
    text-align: left;
    border: 0;
    width: 100%;
  }

  .jslider-label {
    font-size: 10px;
    line-height: 12px;
    color: $danger; opacity: 0.4;
    white-space: nowrap;
    padding: 0px 2px;
    position: absolute;
    top: -18px; left: 0px;

    small {
      position: relative;
      top: -0.4em;
    }
  }

  .jslider-label-to {
    left: auto;
    right: 0;
  }

  .jslider-value {
    font-size: 10px;
    white-space: nowrap;
    padding: 1px 2px 0;
    position: absolute;
    top: -19px;
    left: 20%;
    background: white;
    line-height: 12px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;

    small {
      position: relative;
      top: -0.4em;
    }
  }

  .jslider-value-to {
    left: 80%;
  }

  .jslider-scale {
    position: relative;
    top: 9px;

    span {
      position: absolute;
      height: 5px;
      border-left: 1px solid $gray-500;
      font-size: 0;
    }

    ins {
      font-size: 9px;
      text-decoration: none;
      position: absolute;
      left: 0px;
      top: 5px;
      color: $gray-500;
      white-space: nowrap;
    }
  }

  .jslider-pointer{
    background: #fff;
    border: 2px solid $danger;
    border-radius: 50%;
    height: 16px;
    top: -6px;
    margin-left: -8px;
    width: 16px;
    position: absolute;
    &:hover {
      border: 2px solid $danger;
      cursor: pointer;

    }
  }

  .jslider-pointer-to {
    left: 80%;
  }

  .jslider-value {
    font-size: 12px;
    white-space: nowrap;
    line-height: 12px;
    position: absolute;
	  top: -23px;
	  background: transparent;
  }

  .jslider-scale {
	  position: relative;
	  top: 15px;
  }
}

.ex-price-range {
  strong {
    font-size: 14px;
  }
}
